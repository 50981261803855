<template>
  <money-3 v-model="value" v-bind="config" class="form-control" :class="classNames" @input="updateValue($event)" />
</template>

<script>
import { Money3 } from 'v-money3'

export default {
  name: 'InputCurrency',
  components: {
    Money3
  },
  emits: ['input'],
  props: {
    precision: {
      type: Number,
      default: 2
    },
    thousands: {
      type: String,
      default: '.'
    },
    decimal: {
      type: String,
      default: ','
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: 'right'
    },
    textStyle: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: 0,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: ' #',
        precision: 2,
        masked: false
      }
    }
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue);
    }
  },
  computed: {
    config() {
      return {
        decimal: this.decimal,
        thousands: this.thousands,
        prefix: this.prefix,
        suffix: this.suffix,
        precision: this.precision,
        masked: false
      }
    },
    inputVal: {
      get() {
        return this.value;
      },
    },
    classNames() {
      return `text-${this.align} text-${this.textStyle} ${this.className}`;
    }
  },
}
</script>