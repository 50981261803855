<template>
  <div class="card">    
    <div class="text-center card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0 text-sm text-bolder">{{ description }}</h4>
        <h5 class="text-gradient font-weight-bolder" :class="`text-${color}`">
          <router-link :to="`/admin/pages/clientes-vertical/${tipo.toLowerCase()}`">
            <span :id="id" :countTo="total.quantidade"></span>
          </router-link>
        </h5>
      </div>
      <h4 class="mb-2 font-weight-bolder">{{ filters.currencyWithPrefix(total.total) }}</h4>
      <div v-if="total" class="mb-0 text-sm d-flex align-items-center justify-content-between font-weight-bold">
        <div title="Mensal" class="totais-tipo-recebimento">
          <span class="text-dark">
            MRR({{ total?.mrr?.quantidade }})
          </span>
          <span class="text-dark font-weight-bolder">
            {{ filters.currency(total?.mrr?.valor) }}
          </span>
        </div>
        <div title="Trimestral" class="totais-tipo-recebimento">
          <span class="text-dark">
            TRR({{ total?.trr?.quantidade }})
          </span>
          <span class="text-dark font-weight-bolder">
            {{ filters.currency(total?.trr?.valor) }}
          </span>
        </div>
        <div title="Semestral" class="totais-tipo-recebimento">
          <span class="text-dark">
            SRR({{ total?.srr?.quantidade }})
          </span>
          <span class="text-dark font-weight-bolder">
            {{ filters.currency(total?.srr?.valor) }}
          </span>
        </div>
        <div title="Anual" class="totais-tipo-recebimento">
          <span class="text-dark">
            ARR({{ total?.arr?.quantidade }})
          </span>
          <span class="text-dark font-weight-bolder">
            {{ filters.currency(total?.arr?.valor) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";
import * as uuid from 'uuid';

export default {
  name: "VerticalTotalCard",
  props: {
    description: {
      type: String,
      default: "",
    },
    tipo: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "success",
    },
    total: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: `count_${uuid.v4()}`,
    }
  },
  methods: {
    animate() {
      const element = document.getElementById(this.id);

      if (element) {
        const countUp = new CountUp(
          this.id,
          document.getElementById(this.id).getAttribute("countTo"),
        );

        if (!countUp.error) {
          countUp.start();
        } else {
          console.error(countUp.error);
        }
      } else {
        console.log('Erro ao recuperar o elemento');
      }
    }
  },
  watch: {
    'total.quantidade': function (newValue) {
      if (newValue) {
        setTimeout(() => {
          this.animate();
        }, 200);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-info-card {
  position: absolute;
  margin: 8px;
  right: 0;
  top: 0;
  z-index: 100;
}

.card {
  .card-body {
    padding: 0.5rem 1.0rem !important;

    .totais-tipo-recebimento {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 0.5rem;

      span {
        font-size: 0.7rem;
      }
    }
  }
}
</style>
