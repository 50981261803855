<template>
  <div class="form-check form-switch ps-0 align-items-center d-flex" :class="{ 'flex-row-reverse': reverse }">
    <input :id="id" class="form-check-input ms-0" :class="inputClass" type="checkbox" :name="name"
      @input="updateModelValue($event)" v-model="isChecked" />
    <label class="form-check-label" :class="labelClass" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
import * as uuid from 'uuid';

export default {
  name: "ArgonSwitch",
  emits: ['update:modelValue'],
  props: {
    name: {
      type: String,
      required: false,
      default: `switch_${uuid.v4()}`
    },
    reverse: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      id: `switch_${uuid.v4()}`,
      isChecked: false
    }
  },
  methods: {
    updateModelValue(event) {
      this.$emit('update:modelValue', event.target.checked);
    }
  },
  watch: {
    modelValue: function (value) {
      this.isChecked = value;
    }
  }
};
</script>
