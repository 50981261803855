<template>
    <div>
        <datepicker :locale="locale" :month-change-on-scroll="false" :format="format" auto-apply
            :enable-time-picker="false" class="text-center" @update:model-value="updateValue($event)" v-model="value"
            :position="textAlign" :input-class-name="inputClassName" :clearable="clearable" />
    </div>
</template>
<script>
import Datepicker from '@vuepic/vue-datepicker';
import moment from 'moment';

export default {
    name: "InputDateTime",
    components: {
        Datepicker
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: [String, Date],
            default: null,
        },
        locale: {
            type: String,
            default: 'pt-BR',
        },
        type: {
            type: String,
            default: 'date',
        },
        textAlign: {
            type: String,
            default: 'center',
        },
        clearable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: null,
        };
    },
    methods: {
        updateValue(value) {
            this.value = this.type == 'date' ? moment(value).startOf('day').toDate() : value;
            this.$emit('update:modelValue', value);
        }
    },
    computed: {
        format() {
            return this.type === 'date' ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm';
        },
        inputClassName() {
            return `text-${this.textAlign}`
        }
    },
    watch: {
        modelValue: {
            immediate: true,
            handler(value) {
                this.value = value;
            }
        }
    }
}
</script>