<template>
  <div class="py-2 container-fluid">
    <month-navigator v-model="mes" />
    <totais-churns :totais="totais" />
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12 mt-sm-2">
        <pizza-churn-card title="Motivos" :series="churns.motivos" :on-serie-click="onClickMotivo"
          :selected-item="selectedFilters.motivo" />
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mt-sm-2">
        <pizza-churn-card title="Origem" :series="churns.origens" :on-serie-click="onClickOrigem"
          :selected-item="selectedFilters.origem" />
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mt-sm-2">
        <pizza-churn-card title="Porte" :series="churns.portes" :on-serie-click="onClickPorte"
          :selected-item="selectedFilters.porte" />
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mt-sm-2">
        <pizza-churn-card title="Vertical" :series="churns.verticais" :on-serie-click="onClickVertical"
          :selected-item="selectedFilters.vertical" />
      </div>
    </div>
    <div class="mt-2 row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Clientes({{ clientesFiltered.length }})</h5>
                <div class="d-flex justify-content-start mt-2">
                  <button
                    :class="['btn', selectedFilters.dependeNos == true ? 'btn-danger' : 'btn-outline-danger', 'btn-sm', 'me-2']"
                    type="button" name="button" @click="setDependeNos(true)">
                    Depende de nós
                    <i class="fas fa-thumbs-up"></i>
                  </button>
                  <button
                    :class="['btn', selectedFilters.dependeNos == false ? 'btn-dark' : 'btn-outline-dark', 'btn-sm']"
                    type="button" name="button" @click="setDependeNos(false)">
                    Não depende de nós
                    <i class="fas fa-thumbs-down"></i>
                  </button>
                </div>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                    @click="exportData()">
                    Exportar CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="churns-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="text-xs">Nome</th>
                    <th class="text-xs">Motivo</th>
                    <th class="text-center text-xs">Origem</th>
                    <th class="text-center text-xs">Vertical</th>
                    <th class="text-center text-xs">Depende Nós</th>
                    <th class="text-center text-xs">Fechamento</th>
                    <th class="text-center text-xs">Cancelamento</th>
                    <th class="text-center text-xs">Tempo de Vida</th>
                    <th class="text-center text-xs">Faturamento</th>
                    <th class="text-right text-xs">Valor</th>
                    <th class="text-center" v-if="isExporting">Observações</th>
                    <th class="text-center text-xs" ignore-exportable>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cliente of clientesFiltered" :key="cliente.id">
                    <td class="text-uppercase">
                      <span class="my-2 text-xs">
                        {{ cliente.nome }}
                      </span>
                    </td>
                    <td>
                      <span class="badge badge-xs bg-dark">
                        {{ cliente.motivo }}
                      </span>
                    </td>
                    <td class="text-center">
                      <badge-origem-cancelamento :origem="cliente.origem_cancelamento" size="xs" />
                    </td>
                    <td class="text-center">
                      <badge-vertical :vertical="cliente.vertical" size="xs" />
                    </td>
                    <td class="text-center">
                      <badge-sim-nao :value="cliente.depende_de_nos" size="xs" />
                    </td>
                    <td class="text-center">
                      <span class="badge badge-xs badge-primary">
                        {{ cliente.data_contrato }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="badge badge-xs badge-danger">
                        {{ cliente.data_cancelamento }}
                      </span>
                    </td>
                    <td class="text-center">
                      <badge-tempo-vida :tempo-vida="cliente.tempo_vida" size="xs" />
                    </td>
                    <td class="text-center">
                      <badge-tipo-faturamento :tipo-faturamento="cliente.tipo_faturamento" size="xs" />
                    </td>
                    <td class="text-right text-dark text-bold text-xs">
                      {{ filters.currency(cliente.valorMrr) }}
                    </td>
                    <td class="text-center" v-if="isExporting">
                      {{ cliente.observacoes }}
                    </td>
                    <td class="text-center text-dark text-bold" ignore-exportable>
                      <slot>
                        <button class="btn btn-danger btn-xs btn-view-message py-1 px-2" data-bs-toggle="modal"
                          data-bs-target="#modal-motivo-churn" @click="viewMotivo(cliente)">
                          Motivos
                        </button>
                      </slot>
                      &nbsp;
                      <router-link :to="{ name: 'Cancelamento', params: { id: cliente.id } }"
                        class="btn btn-primary btn-xs py-1 px-2">
                        Editar
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-motivo-churn" class="modal fade" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog mt-lg-10  modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Motivos do Churn
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label>Razão Social</label>
                <input type="text" class="form-control" v-model="clienteSelected.razao_social" disabled />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Data Fechamento</label>
                <input v-model="clienteSelected.data_contrato" class="form-control text-bold text-center " disabled />
              </div>
              <div class="col-md-6">
                <label>Data Cancelamento</label>
                <input v-model="clienteSelected.data_cancelamento" class="form-control text-bold text-center "
                  disabled />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Origem</label>
                <input v-model="clienteSelected.origem" class="form-control text-bold text-center " disabled />
              </div>
              <div class="col-md-6">
                <label>Motivo</label>
                <input v-model="clienteSelected.motivo" class="form-control text-bold text-center " disabled />
              </div>
            </div>
            <div class="row">
              <div class="col-md">
                <label for="">Observações do cancelamento:</label>
                <textarea cols="30" class="form-control text-bold " rows="5" v-model="clienteSelected.observacoes"
                  style="resize: none;" readonly></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PizzaChurnCard from "@/views/dashboards/Cancelamentos/components/PizzaChurnCard.vue";
import TotaisChurns from "./components/TotaisChurns.vue";
import setTooltip from "@/assets/js/tooltip.js";
import BadgeTipoFaturamento from "./../components/BadgeTipoFaturamento.vue";
import BadgeTempoVida from "../../pages/components/BadgeTempoVida.vue";
import BadgeVertical from "./../components/BadgeVertical.vue";
import BadgeOrigemCancelamento from "./../components/BadgeOrigemCancelamento.vue";
import MonthNavigator from "@/components/MonthNavigator.vue";
import BadgeSimNao from "../components/BadgeSimNao.vue";
import api from '@/services/api';
import { exportToCSV } from "@/utils/export";
import moment from "moment";

export default {
  name: "Overview",
  components: {
    PizzaChurnCard,
    TotaisChurns,
    BadgeTipoFaturamento,
    BadgeTempoVida,
    BadgeVertical,
    MonthNavigator,
    BadgeSimNao,
    BadgeOrigemCancelamento
  },
  created() {
    const { mes } = this.$route.params;

    this.mes = moment(mes).toDate();
  },
  data() {
    return {
      mes: '',
      isExporting: false,
      dataTable: null,
      churns: {
        totais: {
          total: 0,
          quantidade: 0,
          ticketMedio: 0
        },
        motivos: [],
        origens: [],
        portes: [],
        verticais: [],
        clientes: [],
      },
      clienteSelected: {},
      selectedFilters: {
        dependeNos: null,
        status: null,
        motivo: null,
        origem: null,
        porte: null,
        vertical: null,
      },
      clientesFiltered: []
    }
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);

    this.fetchData();
  },
  methods: {
    fetchData() {
      this.clientesFiltered = [];

      this.selectedFilters = {
        dependeNos: null,
        status: null,
        motivo: null,
        origem: null,
        porte: null,
        vertical: null,
      };
      api.get(`/dashboard-gerencial/totais-churn`, {
        params: {
          mes: this.mes

        }
      }).then(({ data }) => {
        this.churns = data;

        this.applyFilters();
      });
    },
    exportData() {
      this.isExporting = true;
      const mes = moment(this.mes).format('YYYY-MM-DD');

      setTimeout(() => {
        exportToCSV('churns-list', `churns-${mes}.csv`);
        this.isExporting = false;
      }, 50);
    },
    setDependeNos(value) {
      if (value == this.selectedFilters.dependeNos) {
        value = null;
      }

      this.selectedFilters.dependeNos = value;
      this.applyFilters();
    },
    viewMotivo(cliente) {
      this.clienteSelected = cliente;
    },
    onClickMotivo(item) {
      this.onSelectFilter('motivo', item);
    },
    onClickOrigem(item) {
      this.onSelectFilter('origem', item);
    },
    onClickPorte(item) {
      this.onSelectFilter('porte', item);
    },
    onClickVertical(item) {
      this.onSelectFilter('vertical', item);
    },
    onSelectFilter(name, newValue) {
      const value = this.selectedFilters[name];

      this.selectedFilters[name] = value == newValue.label ? null : newValue.label;

      this.applyFilters();
    },
    applyFilters() {
      const { motivo, origem, porte, vertical, status, dependeNos } = this.selectedFilters;

      let clientesFiltered = [
        ...this.churns.clientes
      ];

      if (motivo) {
        clientesFiltered = clientesFiltered.filter(cliente => cliente.motivo === motivo);
      }

      if (origem) {
        clientesFiltered = clientesFiltered.filter(cliente => cliente.origem === origem);
      }

      if (porte) {
        clientesFiltered = clientesFiltered.filter(cliente => cliente.porte === porte);
      }

      if (vertical) {
        clientesFiltered = clientesFiltered.filter(cliente => cliente.vertical === vertical);
      }

      if (status != null) {
        clientesFiltered = clientesFiltered.filter(cliente => cliente.status === status);
      }

      if (dependeNos != null) {
        clientesFiltered = clientesFiltered.filter(cliente => cliente.depende_de_nos == dependeNos);
      }

      this.clientesFiltered = clientesFiltered;
    },
  },
  computed: {
    totais() {
      const totais = {
        total: 0,
        quantidade: 0,
        ticketMedio: 0
      };

      totais.total = this.clientesFiltered.reduce((acc, cliente) => acc + cliente.valorMrr, 0);
      totais.quantidade = this.clientesFiltered.length;
      totais.ticketMedio = totais.total / totais.quantidade;

      return totais;
    }
  },
  watch: {
    mes: function () {
      this.fetchData();
    }
  }
};
</script>
