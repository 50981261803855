<template>
  <div class="card mb-2" @click="onClick">
    <div class="pt-3 pb-1 text-center card-header">
      <span class="text-uppercase font-weight-bold text-dark overflow-text">
        {{ cliente?.nomeFantasia ?? cliente?.razaoSocial ?? 'Não informado' }}
      </span>
    </div>
    <div class="pt-1 text-center card-body">
      <div class="d-flex justify-content-between">
        <label>Ticket</label>
        <h6 class="mt-0 font-weight-bold text-sm h6-ticket" @click="openTicket($event, cancelamento.movideskTicketId)">
          {{ cancelamento.movideskTicketId }}
        </h6>
      </div>
      <hr class="mt-1 mb-1 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Faturamento</label>
        <h6 class="mt-0 font-weight-bold">
          <badge-tipo-faturamento :tipo-faturamento="cliente?.tipoFaturamento" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-1 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Valor MRR</label>
        <h6 class="mt-0 font-weight-bold text-sm">
          {{ filters.currencyWithPrefix(cliente?.valorMrr) }}
        </h6>
      </div>
      <hr class="mt-1 mb-1 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Motivo</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-motivo-cancelamento :motivo="cancelamento.motivoCancelamento" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-1 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Plano</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-plano :plano="cliente?.plano" :porte="cliente?.porte" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-1 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Tempo de Contrato</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-tempo-vida :tempo-vida="cancelamento.tempoContrato" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Responsável</label>
        <h6 class="mb-0 font-weight-bolder text-xs">
          {{ cancelamento.customerSuccess?.nome }}
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Data da Solicitação</label>
        <h6 class="mb-0 font-weight-bolder text-xs">
          {{ filters.formatDate(cancelamento.data) }}
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Data Ultimo Contato</label>
        <h6 :class="['mb-0', 'font-weight-bolder', 'text-sm', cliente?.ativo ? 'text-navy' : 'text-danger']">
          {{ cancelamento.dataUltimoContato ? filters.formatDate(cancelamento.dataUltimoContato) : '***' }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import BadgePlano from "@/views/pages/components/BadgePlano.vue";
import BadgeMotivoCancelamento from "./BadgeMotivoCancelamento";
import BadgeTempoVida from "@/views/pages/components/BadgeTempoVida.vue";
import BadgeTipoFaturamento from "@/views/pages/components/BadgeTipoFaturamento.vue";

export default {
  name: "CancelamentoCard",
  components: {
    BadgePlano,
    BadgeTempoVida,
    BadgeMotivoCancelamento,
    BadgeTipoFaturamento
  },
  props: {
    cancelamento: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {

    }
  },
  methods: {
    onClick() {
      this.$router.push({
        name: 'Cancelamento',
        params: {
          id: this.cancelamento.id
        }
      });
    },
    openTicket(event, ticketId) {
      event.stopPropagation();
      if (!ticketId) {
        return;
      }

      window.open(`https://bitz.movidesk.com/Ticket/Edit/${ticketId}`, '_blank');
    }
  },
  computed: {
    cliente() {
      return this.cancelamento.cliente;
    }
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 0.4px solid #212529;
  cursor: move;

  label {
    margin-bottom: 0 !important;
  }

  .card-body {
    padding: 1rem;

    label {
      text-align: left;
    }

    .h6-ticket {
      cursor: pointer;
      color: #007bff;
    }
  }

  .overflow-text {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
