<template>
    <argon-button color="dark" variant="gradient" @click="back()" :size="size" class="my-2">
        VOLTAR
    </argon-button>
</template>
<script>
import ArgonButton from "@/components/ArgonButton.vue";
export default {
    props: {
        size: {
            type: String,
            default: "sm",
        },
    },
    components: {
        ArgonButton,
    },
    name: "BackButton",
    methods: {
        back() {
            this.$router.back();
        },
    },
}
</script>
