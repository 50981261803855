<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-outline-success btn-xs mb-0" @click="previous()">
          <i class="fas fa-chevron-left"></i> Ant
        </button>
        <h6 class="mb-2 text-uppercase">
          {{ filters.formatDate(currentMonth, 'MMMM - YYYY') }}
        </h6>
        <button type="button"
          :class="['btn', isNextVisible ? 'btn-outline-success' : 'btn-outline-secondary', 'btn-xs mb-0']"
          :disabled="!isNextVisible" @click="next()">
          Próx <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <div class="card-body pb-0 pt-1">
      <div class="table-responsive">
        <table class="table mb-0">
          <tbody>
            <tr v-for="item in totalMensal" :key="item.tipo">
              <td class="align-middle">
                <router-link :to="item.href && item.valor ? item.href : '#'">
                  <h6 :class="['mb-0 text-xs', item.href && item.valor > 0 ? 'text-underline' : 'no-link']">
                    {{ getTitle(item) }}
                  </h6>
                </router-link>
              </td>
              <td class="align-middle text-center">
                <progress-bar-meta :item="item" />
              </td>
              <td class="text-right">
                <p :class="['font-weight-bold mb-0', `text-${item.className}`]">
                  {{ filters.currencyWithPrefix(item.valor) }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import * as moment from 'moment';
import api from './../../../services/api';
import ProgressBarMeta from '@/views/pages/components/ProgressBarMeta.vue';

export default {
  name: "VendasMensalCard",
  components: {
    ProgressBarMeta
  },
  data() {
    return {
      totalMensal: [],
      isNextVisible: false,
      currentMonth: moment()
        .startOf('month')
        .toDate()
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    next() {
      this.currentMonth = moment(this.currentMonth).add(1, 'month');
      this.fetchData();
    },
    previous() {
      this.currentMonth = moment(this.currentMonth).subtract(1, 'month');
      this.fetchData();
    },
    fetchData() {
      const dataReferencia = moment(this.currentMonth).format('YYYY-MM-DD');

      api.get('/dashboard-gerencial/total-mensal', {
        params: {
          data: dataReferencia
        }
      }).then(response => {
        const { data } = response;
        this.totalMensal = data;
      }).catch(e => console.log(e));
    },
    getTitle(item) {
      const { tipo, quantidade } = item;
      if (tipo === 'RESULTADO') {
        return tipo;
      }

      return `${tipo} (${quantidade})`;
    }
  },
  watch: {
    currentMonth: function (newMonth) {
      const currentMonth = moment()
        .startOf('month')
        .toDate();
      const isNextVisible = moment(currentMonth).isAfter(newMonth);
      this.isNextVisible = isNextVisible;
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
}

.text-underline {
  text-decoration: underline;
}

.no-link {
  cursor: initial;
}
</style>
