import { createRouter, createWebHistory } from "vue-router";
import Gerencial from "../views/dashboards/Gerencial.vue";
// import Financeiro from "../views/dashboards/Financeiro.vue";
import Produto from "../views/dashboards/Produto.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import ReceitasDespesasMensal from "../views/pages/ReceitasDespesasMensal.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ForgotBasic from "../views/auth/reset/Basic.vue";
import ResetBasic from "../views/auth/verification/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Semaforro from "../views/pages/Semaforo/Semaforo.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";

// Auth
import SignIn from "../views/auth/signin/SignIn.vue";
import SignUp from "../views/auth/signup/SignUp.vue";

// Pages
import ClientesVertical from "../views/pages/ClientesVertical.vue";
import Clientes from "../views/pages/Clientes/Clientes.vue";
import Cliente from "../views/pages/Clientes/Cliente.vue";
import Metas from "../views/pages/Metas/Metas.vue";
import Meta from "../views/pages/Metas/Meta.vue";
import Upsells from "../views/pages/Upsell/Upsells.vue";
import Upsell from "../views/pages/Upsell/Upsell.vue";
import Contratos from "../views/pages/Contratos.vue";
import Planos from "../views/pages/Planos.vue";
import Modulos from "../views/pages/Modulos.vue";
import UpSellModulos from "../views/pages/UpSellModulos.vue";
import Modulo from "../views/pages/Modulo.vue";
import Plano from "../views/pages/Plano.vue";
import Churns from "@/views/pages/Churns/Churns.vue";
import Error403 from "@/views/auth/error/Error403.vue";
import { canAccessRoute } from "@/utils/security";
import Implantadores from "@/views/pages/Implantadores/Implantadores.vue";
import Implantador from "@/views/pages/Implantadores/Implantador.vue";
import Closers from "@/views/pages/Closers/Closers.vue";
import Closer from "@/views/pages/Closers/Closer.vue";
import CustomerSuccesses from "@/views/pages/CustomerSuccesses/CustomerSuccesses.vue";
import CustomerSuccess from "@/views/pages/CustomerSuccesses/CustomerSuccess.vue";
import Cancelamentos from "@/views/pages/Cancelamentos/Cancelamentos.vue";
import KanbanCancelamentos from "@/views/pages/Cancelamentos/KanbanCancelamentos.vue";
import Cancelamento from "@/views/pages/Cancelamentos/Cancelamento.vue";
import CustomerSuccessesDashboard from "@/views/dashboards/CustomerSuccess.vue";
import DashboardCancelamentos from "@/views/dashboards/Cancelamentos/Cancelamentos.vue";
import Comercial from "@/views/dashboards/Comercial/Comercial.vue";
import Nps from "@/views/dashboards/Nps/Nps.vue";
import UpSellsMes from "@/views/pages/UpSellsMes.vue";
import NotaVersao from "@/views/pages/NovasVersao/NotaVersao.vue";
import NotasVersao from "@/views/pages/NovasVersao/NotasVersao.vue";
import Inadimplencia from "@/views/dashboards/Inadimplencia/Inadimplencia.vue";
import Onboarding from "@/views/dashboards/Onboarding/Onboarding.vue";
import DownSellsMes from "@/views/pages/DownSellsMes.vue";
import Downsells from "@/views/pages/Downsell/Downsells.vue";
import Downsell from "@/views/pages/Downsell/Downsell.vue";
import MetasComercial from "@/views/dashboards/Metas/Metas.vue";
import Comissoes from "@/views/dashboards/Comissoes/Comissoes.vue";
import CategoriaGerencialMes from "@/views/pages/CategoriaGerencialMes.vue";
import FaturamentoMrr from "@/views/dashboards/FaturamentoMrr/FaturamentoMrr.vue";
import Financeiro from "@/views/dashboards/Financeiro.vue";

const publicRoutes = [
  '/',
  '/404',
  '/403',
  '/500',
  '/signin',
  '/signup',
  '/forgot-password',
];

const beforeEnterRoute = (to, from, next) => {
  if (!isAuthenticated()) {
    next('/signin');
    return;
  }

  next();
}

const isAuthenticated = () => {
  const isAuthenticated = localStorage.getItem('access_token') !== null;

  return isAuthenticated;
}

const isPublicRoute = (to) => {
  return publicRoutes.includes(to.fullPath)
    || to.fullPath.startsWith('/public')
    || to.fullPath.startsWith('/reset-password');
}

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signin"
  },
  {
    path: "/signin",
    name: "Signin",
    component: SignIn
  },
  {
    path: "/forgot-password",
    name: "Forgot Basic",
    component: ForgotBasic
  },
  {
    path: "/reset-password/:token",
    name: "Reset Basic",
    component: ResetBasic
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignUp
  },
  {
    path: '/admin',
    beforeEnter: beforeEnterRoute,
    name: 'Admin',
    redirect: '/admin/dashboards/gerencial',
    children: [
      {
        path: "/admin/dashboards/gerencial",
        name: "Gerencial",
        component: Gerencial
      },
      {
        path: "/admin/dashboards/faturamento-mrr",
        name: "Faturamento x MRR",
        component: FaturamentoMrr
      },
      {
        path: "/admin/dashboards/financeiro",
        name: "Financeiro",
        component: Financeiro
      },
      {
        path: "/admin/dashboards/inadimplencia",
        name: "Inadimplência",
        component: Inadimplencia
      },
      {
        path: "/admin/dashboards/comercial",
        name: "Comercial",
        component: Comercial
      },
      {
        path: "/admin/dashboards/metas",
        name: "Metas Comercial",
        component: MetasComercial
      },
      {
        path: "/admin/dashboards/nps",
        name: "Nps",
        component: Nps
      },
      {
        path: "/admin/dashboards/onboarding",
        name: "Onboarding",
        component: Onboarding
      },
      {
        path: "/admin/dashboards/produto",
        name: "Produto",
        component: Produto
      },
      {
        path: "/admin/dashboards/customer-success",
        name: "Dashboard Customer Success",
        component: CustomerSuccessesDashboard
      },
      {
        path: "/admin/dashboards/solicitacao-cancelamento",
        name: "Dashboard Cancelamentos",
        component: DashboardCancelamentos
      },
      {
        path: "/admin/dashboards/smart-home",
        name: "Smart Home",
        component: SmartHome
      },
      {
        path: "/admin/dashboards/crm",
        name: "CRM",
        component: CRM
      },
      {
        path: "/admin/dashboards/comissoes/:mes",
        name: "Comissões",
        component: Comissoes
      },
      {
        path: "/admin/pages/planos",
        name: "Planos",
        component: Planos
      },
      {
        path: "/admin/pages/modulos",
        name: "Modulos",
        component: Modulos
      },
      {
        path: "/admin/pages/modulo/:id",
        name: "Modulo",
        component: Modulo
      },
      {
        path: "/admin/pages/plano/:id",
        name: "Plano",
        component: Plano
      },
      {
        path: "/admin/processos/upsell-modulos",
        name: "Upsell Modulos",
        component: UpSellModulos
      },
      {
        path: "/admin/processos/upsells",
        name: "Upsells",
        component: Upsells
      },
      {
        path: "/admin/processos/upsell/:id",
        name: "Upsell",
        component: Upsell
      },
      {
        path: "/admin/processos/downsells",
        name: "Downsells",
        component: Downsells
      },
      {
        path: "/admin/processos/downsell/:id",
        name: "Downsell",
        component: Downsell
      },
      {
        path: "/admin/pages/contratos",
        name: "Contratos",
        component: Contratos
      },
      {
        path: "/admin/pages/clientes-vertical/:vertical",
        name: "ClientesVertical",
        component: ClientesVertical
      },
      {
        path: "/admin/pages/clientes",
        name: "Clientes",
        component: Clientes
      },
      {
        path: "/admin/pages/cliente/:id",
        name: "Cliente",
        component: Cliente
      },
      {
        path: "/admin/pages/metas",
        name: "Metas",
        component: Metas
      },
      {
        path: "/admin/pages/metas",
        name: "Metas",
        component: Metas
      },
      {
        path: "/admin/pages/meta/:id",
        name: "Meta",
        component: Meta
      },
      {
        path: "/pages/mensal/:categoria/:mes",
        name: "Movimentação Financeira",
        component: CategoriaGerencialMes
      },
      {
        path: "/pages/mensal/upsell/:mes",
        name: "UpSells Mensal",
        component: UpSellsMes
      },
      {
        path: "/pages/mensal/downsell/:mes",
        name: "DownSells Mensal",
        component: DownSellsMes
      },
      {
        path: "/pages/mensal/churn/:mes",
        name: "Churns",
        component: Churns
      },
      {
        path: "/pages/:categoria/:mes",
        name: "Receitas Mensal",
        component: ReceitasDespesasMensal
      },
      {
        path: "/pages/:categoria/:mes",
        name: "Despesas Mensal",
        component: ReceitasDespesasMensal
      },
      {
        path: "/pages/profile/overview",
        name: "Profile Overview",
        component: Overview
      },
      {
        path: "/pages/profile/teams",
        name: "Teams",
        component: Teams
      },
      {
        path: "/pages/profile/projects",
        name: "All Projects",
        component: Projects
      },
      {
        path: "/pages/projects/general",
        name: "General",
        component: General
      },
      {
        path: "/pages/projects/timeline",
        name: "Timeline",
        component: Timeline
      },
      {
        path: "/pages/projects/new-project",
        name: "New Project",
        component: NewProject
      },
      {
        path: "/pages/pricing-page",
        name: "Pricing Page",
        component: Pricing
      },
      {
        path: "/pages/rtl-page",
        name: "RTL",
        component: RTL
      },
      {
        path: "/pages/charts",
        name: "Charts",
        component: Charts
      },
      {
        path: "/pages/widgets",
        name: "Widgets",
        component: Widgets
      },
      {
        path: "/pages/sweet-alerts",
        name: "Sweet Alerts",
        component: SweetAlerts
      },
      {
        path: "/pages/notifications",
        name: "Notifications",
        component: Notifications
      },
      {
        path: "/applications/kanban",
        name: "Kanban",
        component: Kanban
      },
      {
        path: "/applications/wizard",
        name: "Wizard",
        component: Wizard
      },
      {
        path: "/applications/data-tables",
        name: "Data Tables",
        component: DataTables
      },
      {
        path: "/applications/calendar",
        name: "Calendar",
        component: Calendar
      },
      {
        path: "/applications/analytics",
        name: "Analytics",
        component: Analytics
      },
      {
        path: "/ecommerce/overview",
        name: "Overview",
        component: EcommerceOverview
      },
      {
        path: "/ecommerce/products/new-product",
        name: "New Product",
        component: NewProduct
      },
      {
        path: "/ecommerce/products/edit-product",
        name: "Edit Product",
        component: EditProduct
      },
      {
        path: "/ecommerce/products/product-page",
        name: "Product Page",
        component: ProductPage
      },
      {
        path: "/ecommerce/products/products-list",
        name: "Products List",
        component: ProductsList
      },
      {
        path: "/ecommerce/Orders/order-details",
        name: "Order Details",
        component: OrderDetails
      },
      {
        path: "/ecommerce/Orders/order-list",
        name: "Order List",
        component: OrderList
      },
      {
        path: "/ecommerce/referral",
        name: "Referral",
        component: Referral
      },
      {
        path: "/pages/users/reports",
        name: "Reports",
        component: Reports
      },
      {
        path: "/pages/users/new-user",
        name: "New User",
        component: NewUser
      },
      {
        path: "/pages/account/settings",
        name: "Settings",
        component: Settings
      },
      {
        path: "/pages/account/billing",
        name: "Billing",
        component: Billing
      },
      {
        path: "/pages/account/invoice",
        name: "Invoice",
        component: Invoice
      },
      {
        path: "/pages/account/Security",
        name: "Security",
        component: Security
      },
      {
        path: "/authentication/signin/basic",
        name: "Signin Basic",
        component: Basic
      },
      {
        path: "/authentication/signin/cover",
        name: "Signin Cover",
        component: Cover
      },
      {
        path: "/authentication/signin/illustration",
        name: "Signin Illustration",
        component: Illustration
      },
      {
        path: "/authentication/reset/cover",
        name: "Reset Cover",
        component: ResetCover
      },
      {
        path: "/authentication/reset/illustration",
        name: "Reset Illustration",
        component: ResetIllustration
      },
      {
        path: "/authentication/lock/basic",
        name: "Lock Basic",
        component: lockBasic
      },
      {
        path: "/authentication/lock/cover",
        name: "Lock Cover",
        component: lockCover
      },
      {
        path: "/authentication/lock/illustration",
        name: "Lock Illustration",
        component: lockIllustration
      },
      {
        path: "/authentication/verification/cover",
        name: "Verification Cover",
        component: VerificationCover
      },
      {
        path: "/authentication/verification/illustration",
        name: "Verification Illustration",
        component: VerificationIllustration
      },
      {
        path: "/authentication/signup/basic",
        name: "Signup Basic",
        component: SignupBasic
      },
      {
        path: "/authentication/signup/cover",
        name: "Signup Cover",
        component: SignupCover
      },
      {
        path: "/authentication/signup/illustration",
        name: "Signup Illustration",
        component: SignupIllustration
      },
      {
        path: "/authentication/error/error404",
        name: "Error Error404",
        component: Error404
      },
      {
        path: "/authentication/error/error403",
        name: "Error 403",
        component: Error403
      },
      {
        path: "/authentication/error/error500",
        name: "Error Error500",
        component: Error500
      },
      {
        path: "/admin/pages/closers",
        name: "Closers",
        component: Closers
      },
      {
        path: "/admin/pages/closer/:id",
        name: "Closer",
        component: Closer
      },
      {
        path: "/admin/pages/implantadores",
        name: "Implantadores",
        component: Implantadores
      },
      {
        path: "/admin/pages/implantador/:id",
        name: "Implantador",
        component: Implantador
      },
      {
        path: "/admin/pages/customer-successes",
        name: "Customer Successes",
        component: CustomerSuccesses
      },
      {
        path: "/admin/pages/customer-success/:id",
        name: "Customer Success",
        component: CustomerSuccess
      },
      {
        path: "/admin/pages/notas-versao",
        name: "Notas de Versão",
        component: NotasVersao
      },
      {
        path: "/admin/pages/nota-versao/:id",
        name: "Nota de Versão",
        component: NotaVersao
      },
      {
        path: "/admin/processos/cancelamentos",
        name: "Cancelamentos",
        component: Cancelamentos
      },
      {
        path: "/admin/processos/kanban",
        name: "Kanban Cancelamentos",
        component: KanbanCancelamentos
      },
      {
        path: "/admin/processos/cancelamento/:id",
        name: "Cancelamento",
        component: Cancelamento
      },
    ]
  },
  {
    path: "/public/semaforo",
    name: "semaforo",
    component: Semaforro,
  },
  {
    path: "/403",
    name: "not_allowed",
    component: Error403,
  },
  {
    path: "/404",
    name: "page_not_found",
    component: Error404,
  },
  {
    path: "/:catchAll(.*)",
    component: Error404
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {

  if (isPublicRoute(to)) {
    next();
    return;
  }

  if (!isAuthenticated()) {
    next('/signin');
    return;
  }

  if (!canAccessRoute(to)) {
    next('/403');
    return;
  }

  next();
})

export default router;
