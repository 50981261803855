<template>
  <div class="container-fluid py-2">
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header pb-0">
            <div class="d-lg-flex">
              <h5 class="mb-0">
                Faturamento Mensal x MRR
              </h5>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                    @click="exportData()" v-if="items.length > 0">
                    Exportar CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="loading-div" v-if="isLoading">
              <span class="mb-4">
                Aguarde um momento, estamos buscando os dados de faturamento...
              </span>
              <i class="fas fa-spinner fa-pulse fa-3x"></i>
            </div>
            <div class="table-responsive" :hidden="isLoading">
              <table id="faturamento-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>Nome Fantasia</th>
                    <th data-type="date">Dt Contrato</th>
                    <th>Vertical</th>
                    <th data-type="numeric">Fat. Mensal</th>
                    <th data-type="number">Vlr. MRR</th>
                    <th>Percentual</th>
                    <th exportable="false">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item of items" :key="item.id">
                    <td>
                      <div class="d-flex align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ item.id }}
                        </p>
                      </div>
                    </td>
                    <td class="font-weight-bold">
                      <span class="my-2 text-xs">
                        {{ item.nomeFantasia.toUpperCase() }}
                      </span>
                    </td>
                    <td class="font-weight-bold" :data-sort="item.dataContrato">
                      {{ item.dataContrato }}
                    </td>
                    <td>
                      <badge-vertical :vertical="item.vertical" size="xs" />
                    </td>
                    <td class="text-xs font-weight-bold text-end" :data-sort="item.faturamentoMensal">
                      {{ item.faturamentoMensal }}
                    </td>
                    <td class="text-xs font-weight-bold text-end" :data-sort="item.valorMrr">
                      {{ item.valorMrr }}
                    </td>
                    <td class="text-xs font-weight-bold text-center" :data-sort="item.percentual">
                      {{ item.percentual }}
                    </td>
                    <td class="text-center">
                      <a class="btn btn-xs btn-info" :href="`/admin/pages/cliente/${item.id}?tab=1`" target="_blank">
                        Detalhes
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import { DataTable } from "simple-datatables";
import BadgeVertical from "@/views/pages/components/BadgeVertical.vue";
import { exportDataTable } from "@/utils/export";

export default {
  name: "FaturamentoMrr",
  components: {
    BadgeVertical,
  },
  data() {
    return {
      isLoading: true,
      items: [],
      dataTable: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    setDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
        this.dataTable = null;
      }

      this.dataTable = new DataTable("#faturamento-list", {
        searchable: true,
        fixedHeight: false,
        paging: true,
        language: 'pt-BR',
        perPage: 50,
        perPageSelect: [10, 25, 50, 100, 200],
        labels: {
          perPage: "Registros por página",
          info: "Visualizando {start} até {end} de {rows} registros",
          placeholder: "Pesquisar",
        },
        columns: [
          // Set the third column as datetime string matching the format "DD/MM/YYY"
          {
            select: 2,
            type: 'date',
            sortable: true,
            render: (data) => {
              const value = this.filters.formatDate(data);
              return ` <span class="badge badge-xs badge-primary">${value}</span>`;
            }
          },
          {
            select: 4,
            type: 'number',
            sort: 'desc',
            render: (data) => {
              const value = this.filters.currencyWithPrefix(data);
              return `<span class="my-2 text-xs">${value}</span>`;
            },
          },
          {
            select: 5,
            type: 'number',
            render: (data) => {
              const value = this.filters.currencyWithPrefix(data);
              return `<span class="my-2 text-xs">${value}</span>`;
            }
          },
          {
            select: 6,
            type: 'number',
            render: (data) => {
              return `<span class="my-2 text-xs">${(data ?? 0).toFixed(2)}%</span>`;
            }
          },
          { select: 7, sortable: false, exportable: false },
        ]
      }
      );
    },
    exportData() {
      const type = 'csv';

      let data = {
        type: type,
        filename: `faturamento-x-mrr`,
      };

      if (type === "csv") {
        data.columnDelimiter = ";";
      }

      exportDataTable(this.dataTable, 'faturamento-x-mrr');
    },
    fetchData() {
      api.get("/dashboard-gerencial/faturamento-mensal-x-mrr")
        .then((response) => {
          this.items = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    }
  },
  watch: {
    items: function (newValue, oldValue) {
      if (newValue != oldValue) {
        setTimeout(() => {
          this.setDataTable();
        }, 50);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.5rem;
  flex-direction: column;
}
</style>